
html.semi-dark .topbar {
	background-color: #171717;
	border-bottom: 1px solid rgb(237 237 237 / 12%);
}
html.semi-dark .user-box {
	border-left: 1px solid rgb(255 255 255 / 0.15);
}
html.semi-dark .user-info .user-name {
	color: #e4e5e6;
}
html.semi-dark .user-info .designattion {
	color: #b3b3b3
}
html.semi-dark .mobile-toggle-menu {
	color: #fff;
}
html.semi-dark .logo-text {
    color: #fff;
}
html.semi-dark .topbar .navbar-nav .nav-link {
	border-left: 0px solid rgb(255 255 255 / 12%);
	color: #e4e5e6;
}

html.semi-dark .topbar-logo-header{
	border-right: 1px solid rgb(255 255 255 / 14%);
}

html.semi-dark .search-bar input {
	color: #e4e5e6;
	background-color: #1e1e1e;
	border: 1px solid rgb(241 241 241 / 15%);
	box-shadow: inset 0 1px 2px rgb(0 0 0 / 0%);
}
html.semi-dark .search-bar input::placeholder {
	color: #e4e5e6 !important;
	opacity: .5 !important;
	/* Firefox */
}
html.semi-dark::placeholder {
	color: #e4e5e6 !important;
	opacity: .5 !important;
	/* Firefox */
}
html.semi-dark .search-bar .btn-search {
	background: #1e1e1e;
	border: 1px solid rgb(217 211 211 / 30%);
}

html.semi-dark .search-show {
    color: #ffffff;
}

html.semi-dark .nav-container {
    background: #171717;
    border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.semi-dark .wrapper.toggled .mobile-topbar-header {
    background: #171717;
    border-bottom: 1px solid rgb(241 241 241 / 12%);
}
html.semi-dark .toggle-icon {
    color: #ffffff;
}
html.semi-dark .topbar-nav .metismenu a {
	color: #9ea4aa;
}
html.semi-dark .topbar-nav .metismenu a:hover,
html.semi-dark .topbar-nav .metismenu a:focus,
html.semi-dark .topbar-nav .metismenu a:active,
html.semi-dark .topbar-nav .metismenu .mm-active>a {
	color: #fff;
	text-decoration: none;
	background: rgb(255 255 255 / 18%);
}
html.semi-dark .topbar-nav .metismenu ul {
	border: 1px solid #ffffff00;
	background: #171717;
}
html.semi-dark .simplebar-scrollbar:before {
	background: rgba(255, 255, 255, 0.40);
}
html.semi-dark .logo-icon {
	filter: invert(1) grayscale(100%) brightness(200%);
}



/* Minimal Theme */

html.minimal-theme .topbar {
	background: #f7f7ff;
	border-bottom: 1px solid #dee2e6;
	box-shadow: none !important
}
html.minimal-theme .topbar .search-control {
	background-color: #ffffff;
	border: 1px solid #dee2e6;
}
html.minimal-theme .topbar .user-box {
	border-left: 1px solid #dee2e6;
}
html.minimal-theme .nav-container {
	background: #f7f7ff;
	border-bottom: 1px solid #dee2e6;
	box-shadow: none !important
}
html.minimal-theme .topbar-logo-header {
	border-right: 1px solid #dee2e6;
}


@media screen and (max-width: 1280px){

	html.minimal-theme .wrapper.toggled .mobile-topbar-header {
		background: #f7f7ff;
		border-bottom: 1px solid #dee2e6;
	}
	
}


@media screen and (min-width: 1025px) {
	html.minimal-theme .wrapper.toggled.sidebar-hovered .sidebar-wrapper {
		box-shadow: 0 .3rem .8rem rgba(0, 0, 0, .12)!important;
	}
}

/*Metismenu*/

.nav-container {

	position: fixed;

	top: 60px;

	right: 0;

	left: 0;

	z-index: 9;

	border-bottom: 1px solid #e4e4e4;
	
	box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;

	background-clip: border-box;

	background: #ffffff;

	width: 100%;

	height: 60px;

	padding: 0px 30px;

	display: flex;

	align-items: center;

	justify-content: center;

}

.topbar-nav {

	background: transparent;

	width: 100%;

}

.topbar-nav ul {

	padding: 0;

	margin: 0;

	list-style: none;

	background: transparent;

}

.topbar-nav .metismenu {

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

}

.topbar-nav .metismenu > li {

	-webkit-box-flex: 1;

	-ms-flex: 1 1 0%;

	flex: 1 1 0%;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

	position: relative;

}

.topbar-nav .metismenu a {

	position: relative;

	display: flex;

	align-items: center;

	justify-content: left;

	padding: 10px 15px;

	color: #5f5f5f;

	outline-width: 0;

	transition: all .1s ease-out;

}

.topbar-nav .metismenu ul a {

	padding: 8px 15px 8px 15px;

	font-size: 15px;

	border: 0;

}

.topbar-nav .metismenu ul a i {

	margin-right: 10px;

}

.topbar-nav .metismenu a .parent-icon {

	font-size: 24px;

	line-height: 1;

}

.topbar-nav .metismenu a .menu-title {

	margin-left: 10px;

}

.topbar-nav .metismenu a:hover,

.topbar-nav .metismenu a:focus,

.topbar-nav .metismenu a:active {

	color: #0d6efd;

	text-decoration: none;

	background: #0d6efd1f;

}

@media (min-width: 1281px) {

	.topbar-nav .metismenu {

		-webkit-box-orient: horizontal;

		-webkit-box-direction: normal;

		-ms-flex-direction: row;

		flex-direction: row;

	}

	.topbar-nav .metismenu > li {

		-webkit-box-orient: vertical;

		-webkit-box-direction: normal;

		-ms-flex-direction: column;

		flex-direction: column;

	}

	.topbar-nav .metismenu > li > ul {

		position: absolute;

		top: 100%;

		min-width: 100%;

		z-index: 1001;

		background: white;

		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);

	}

	.topbar-nav.is-hoverable .metismenu > li > ul {

		height: auto !important;

	}

	.topbar-nav.is-hoverable .metismenu > li:hover > ul {

		display: block;

	}

	.topbar-nav.is-hoverable .metismenu > li:hover > a.has-arrow:after {

		transform: rotate(-135deg) translateY(-50%);

	}

}


@media screen and (min-width: 1025px) {



	.email-toggle-btn {


		display: none !important;


	}


	.chat-toggle-btn {


		display: none !important;


	}


}
